import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// TODO: Move translations to a JSON file or locize
const resources = {
    en: {
      translation: {
        "programTitle": "Privilege Program",
        "navLinkResources": "Resources",
        "navLinkHome": "Home",
        "footerMoreDetails": "For more details, speak to your supplying Altrom Auto Group branch representative today!"
      }
    },
    fr: {
      translation: {
        "programTitle": "Programme Privilège",
        "navLinkResources": "Ressources",
        "navLinkHome": "Accueil",
        "footerMoreDetails": "Pour plus de détails, contactez le représentant de la succursale Altrom Auto Group de votre région dès aujourd'hui!"
      }
    }
  };
  
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: "en",
  
      keySeparator: false, // we do not use keys in form messages.welcome
  
      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
  
    export default i18n;