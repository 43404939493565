import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AAGBrochureEN from './pdf/NAPA AAG 2022 Launch Brochure-EN-20201204-online.pdf'
import AAGBrochureFR from './pdf/NAPA AAG 2022 Launch Brochure-FR-20201217-online.pdf'
import VisaOrderForm from './pdf/AAG Privilege Visa Order Form_04-12-2021.pdf'
import NoticeOfModificationForm from './pdf/NAPA AAG Notice of Modification form.pdf'
import './styles/styles.css'

export default function App() {
  const { t, i18n } = useTranslation()

  const [ resourcesClicked, setResourcesClicked ] = useState(false)

  const resources = {
    en: [
      ['NAPA AAG Launch Brochure']
    ],
    fr: [
      ['NAPA AAG Brochure de lancement']
    ]
  }

  const handleClick = () => {
    setResourcesClicked(!resourcesClicked)
  }

  return (
    <div className='container'>
      <header className='header'>
        <div className='logo' onClick={() => setResourcesClicked(false) }></div>
        <div className='program-title'>
          { t('programTitle', 'Privilege Program') }
          <span className='lang-toggle' onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en')}>
            { i18n.language === 'fr' ? 'EN' : 'FR' }
          </span>
        </div>
      </header>
      <nav className='nav'>
        <p className='nav-link'>
          <span className='resources-link' 
              onClick={ handleClick }
          >
            { !resourcesClicked ? t('navLinkResources', 'Resources') : t('navLinkHome', 'Home') }
          </span>
        </p>
      </nav>

      { !resourcesClicked &&  
        <>
          <div className='split-grid'>
            <div className='video-responsive'>
              <iframe title='Privilege video' 
                    src={`https://player.vimeo.com/video/${ i18n.language === 'fr' ? '389254208' : '389254344' }`} 
                    width="600" 
                    height="338" 
                    frameBorder="0" 
                    webkitallowfullscreen="" 
                    mozallowfullscreen="" 
                    allowFullScreen=""
              />
            </div>
            <div className='trip-images'></div>
          </div>
        </>
      }

      { resourcesClicked && 
        <ul className='resources-list'>
          { resources[i18n.language].map((resource, i) => (
            <li key={i} className='resources-list--resource'>
                <a target="_blank" rel='noopener noreferrer' href={ i18n.language === 'fr' ? AAGBrochureFR : AAGBrochureEN } className='pdf'>
                  { resource[0] }
                </a>
            </li>
          )) }
          <li className='resources-list--resource'>
              <a target="_blank" rel='noopener noreferrer' href={ VisaOrderForm } className='pdf'>
                { i18n.language === 'fr' ? `NAPA AAG Formulaire de commande` : `NAPA AAG Privilege Visa Order Form` }
              </a>
          </li>
          <li className='resources-list--resource'>
              <a target="_blank" rel='noopener noreferrer' href={ NoticeOfModificationForm } className='pdf'>
                { i18n.language === 'fr' ? `NAPA AAG Avis De Modification` : `NAPA AAG Notice of Modification Form` }
              </a>
          </li>
        </ul>
      }

      <footer className='footer'>
          <p className='text-center footer-more-details'>
            { t('footerMoreDetails', 'For more details, speak to your supplying Altrom Auto Group branch representative today!') }
          </p>
      </footer>
    </div>
  );
}
